* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif !important;

}

.section12 {
    margin-top: 102px;
}

.section13 h6,
.section15 h6,
.section14 h6,
.VISION h6 {
    color: #00008B;
}

.section13 p,
.section14 p,
.section15 p,
.VISION p {
    text-align: justify;
}

.section15 {

    background-color: #fad70326;
}

.section14 img {
    height: 450px;
}

@media screen and (max-width:768px) {

    .about1 {
        top: 130px !important;
    }




}

@media screen and (max-width:992px) {}

@media screen and (max-width:1280px) {}

@media screen and (max-width:1680px) {}

@media screen and (max-width:600px) {
    .section12{
        height: 20vh;
        margin-top: 90px;
    }
    .section12 img{
        height: 100%;
    }
}

@media screen and (max-width:414px) {}