* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.form-heading button {
  border: none;
  color: black
}

.buttonForm, .overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay {
  background: rgba(49, 49, 49, 0.7);
}
.card-1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* max-height: 600px; */
  background-color: white;
  z-index: 1000  ;
  max-width: 980px;
  border-radius: 8px;
}

.card-content .col-lg-4 img {
  margin-top: 180px;
}

@media screen and (max-width:414px) {
  .buttonForm {
    display: none !important;
  }
}

@media screen and (max-width:600px) and (max-width:768px) {
  .buttonForm {
    display: none !important;
  }
}