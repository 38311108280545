/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 75px;
    /* height: 160px; */
    bottom: 30px;
    right: 30px;
    /* background-color: #25d366; */
    color: #FFF;
    z-index: 1000;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 100px;
        /* height: 40px; */
        bottom: 30px;



    }
}

.quick-link>p {
    margin-bottom: 0.2rem;
}