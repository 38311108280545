* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.section32{
    margin-top: 102px;
}

.section33 button:hover{
 background-color:  #FAD703;
 color: black;
 border: none;
}
.section34 .tab-content{
   width: 350px;
}
.phone{
    min-height: 55px !important;
}
.select{
    max-width: 80px !important;
}

@media screen and (max-width:768px) {
    .contact1 {
        top: 210px;
    }

}

@media screen and (max-width:414px) {
    
    .contact1 {
        top: 190px !important;
    }

    .section32 {
        height: 23vh;
    }

    .section32 img {
        height: 100%;
    }
    .section34 .tab-content{
        width: 300px;
     }
}
@media screen and (max-width:1680px) {
.contact1{
    top: 254px;
}
}
